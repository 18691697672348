footer {
    background-color: var(--light-grey);
    position: relative;  
    
    .logo {
        max-width: 15rem;        
       
    }

    .footer-header {
        display: flex;
        justify-content: space-between;
        padding: 3rem 0;
        width: 100%;       
        @media(max-width: 480px) {
            flex-direction: column;
            align-items: center; 
            .logo {
                padding-bottom: 1rem;
            }           
        }
    }
    .footer-art {
        img {
            max-width: 40rem;
            position: absolute;
        }
    }
    .footer {
        width: 100%;
        @media(max-width: 767px) {
            padding: 0 4rem;
        }
        img {
            max-width: 40rem;
        }
    }
    .social-media-icons {
        display: flex;
        width: 50%;
        justify-content: space-between;
        max-width: 20rem;
        img{
            max-width: 3rem;
        }
    }
    .footer-menu {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;       

        div {
            margin: 5px 0;
        }

        h4 {
            padding-bottom: 2rem;
            font-size: 2rem;
            opacity: 0.5;
        }
        li {
            padding: 5px 0;
            font-weight: 700;
        }
        @media(max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 480px) {
            grid-template-columns: repeat(1, 1fr);
            align-items: center;
            div {
                text-align: center;
            }
        }
    }
}