.about {
  width: 100%;
  padding-top: 7.5rem;

  img {
    max-width: 40rem;
  }

  &__content {
    padding-top: 5rem;
    
    h2 {
          padding: 1.5rem 0;         
          font-weight: 700;
        }
    
    p {
      padding: 1rem;
      font-size: 2rem;
    }
    @media (max-width: 767px) {
      text-align: center;
      p {
        max-width: 70%;
        margin: auto;
      }
    }
  }
  &__contact {   
    display: inline-block;
    padding-top: 2rem;
    padding: 0 25px;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    line-height: 50px;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    
    img {
      max-width: 20rem;
    }
    .wrapper{
        padding: 2px 16px;
        h4 {
            font-weight: 600;
        }
    }      
    @media (max-width: 767px) {
      display: block;
      justify-content: center;
      padding: 1rem;
      border-radius: 0;

    }
  }
  &__contact:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  h2 {
      padding: 1.5rem 0;
      font-weight: 500;
    }
  @media (max-width: 767px) {
    padding-top: 2rem;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
    .title1 {
      text-align: center;
    }
  }
}
