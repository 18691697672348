:root {
  // --dark-violet: hsl(256, 26%, 20%);
  --dark-green: hsl(148, 94%, 21%);
  --grey-blue: hsl(216, 30%, 68%);
  --very-dark-violet: hsl(270, 9%, 17%);
  --dark-grey-violet: hsl(273, 4%, 51%);
  --light-grey: hsl(0, 0%, 98%);
  --white: #fff;
  --fw-700: 700;
  --fw-400: 400;
}
