.xaalada {
    width: 100%;
    padding-top: 3rem;
  
    img {
      max-width: 50rem;
    }
  
    &__content {
      padding-top: 5rem;
  
      h2 {
        padding: 1.5rem 0;
        font-weight: 700;
      }
      h3{
        padding: 1.5rem 0;
        font-weight: 700;
    }
    p {
        padding: 0;
    }
  
      
      @media (max-width: 767px) {
        text-align: center;
        p {
          max-width: 70%;
          margin: auto;
        }
      }
    }

    &__list {    
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;    
      .wrapper {       
          text-align: center;       
          // border-radius: 25px;        
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;  
          img{
            max-width: 10rem;
        }
          p {
              font-size: 2.5rem;
              font-weight: 500;
            }
      
      }
      .wrapper:hover {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        }     
  
      
     
     
      @media (max-width: 767px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 1rem;
        border-radius: 0;
      }
    }
    
    &__info {
      padding: 1rem;
      p {
          font-size: 20px;
          font-weight: 400;
      }
      @media(max-width: 767px) {
          text-align: center;
      }
    }
  
    h2 {
      padding: 1.5rem 0;
      font-weight: 500;
    }
    @media (max-width: 767px) {
      padding-top: 2rem;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
      .title1 {
        text-align: center;
      }
    }
  }
  