header {
    width: 100%;
    // height: 80px;
    display: flex;
    align-items: center;
    background-color: var(--white);
    box-shadow: 3px 3px 3px rgba($color: #000000, $alpha: 0.2);

    .navbar {
        display: flex;
        justify-content: space-between; 
        align-items: center; 
        padding: 2rem;
        position: relative;
        z-index: 999;
        width: 100%;
        img {
            // max-width: 5rem;
            // max-width: 15rem;
            max-width: 20rem;
        }
        .nav-items {
            display: flex;
            width: 100%;
            max-width: 50rem;
            justify-content: space-between;
            align-items: center;
            color: var(-darkgreen);
          
            li {
                // margin-right: 1rem;
                font-size: 2rem;
            }
            @media(max-width: 767px) {
                display: none;

                &.active {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    width: 100%;
                    max-width: 100%;
                    z-index: 999;
                    background-color: var(--white);
                    height: 30vh;
                    margin: auto;
                    left: 0;
                    padding: 2rem 0;
                    top: 7rem;
                }
            }
        }
        .hamburger {
            display: none;
            @media(max-width: 767px) {
                display: block;
               
            }
        }
        .nav-call{
            display: flex;
            justify-content: space-between;
            position: relative;
            
            button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: none;
                font-size: 2rem;
                img{
                    max-width: 4rem;
                }
                
            }
            
            
        }

    }
    .call {
        display: flex;
        align-items: center;
        padding: 0 2rem;
        height: 50px;
        font-size: 1.8rem;
        line-height: 50px;
        border-radius: 25px;
        background-color: #f1f1f1;
        color: green;
        img {
            float: left;
            margin: 0 10px 0 -25px;
            height: 50px;
            width: 50px;
            border-radius: 50%;
          }
          @media(max-width: 767px) {
            display: none
        }
      }
}