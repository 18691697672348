.banner {
    width: 100%;
    position: relative;
    color: var(--white);
    overflow: hidden;

    
    .title1 {
        &::before {
            content: none;
        }
    }
    &__wrapper {
        display: flex;  
        background-color: var(--dark-green);
        padding: 5rem 0;
        position: relative;
        justify-content: space-around;
        align-items: center;


        button {
            margin-left: auto;
        }
        @media(max-width: 767px) {
            flex-direction: column;
            text-align: center;
            button {
                margin-top: 1rem;
            }
        }
    }
    img {
        max-width: 38rem;
        position: absolute;
        right: 0;
        top: 2rem;
        z-index: 99;
        @media(max-width: 767px) {
            max-width: 15rem;
            top: 0;
        }
    }
}